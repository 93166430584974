<template>
  <div id="exhibition_list">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- list -->
        <div class="col-lg-8">
          <h2 v-if="currentLocation" class="list_title"
            v-html="translate.global.exponate_ausstellung_an_ort[language] + ': ' +
            currentLocation['dcterms:title'][0]['@value']"></h2>
          <ExhibitionList v-if="currentLocation && hasDatePhotoInExhibition(photoId)"
            v-for="(photoId, i) in photosInExhibition"
            :photoId="photoId"
            :key="'photolist_item_inex_' + i" />
          <h2 v-if="currentLocation" class="list_title"
            v-html="translate.global.exponate_bestand_an_ort[language] + ': ' +
            currentLocation['dcterms:title'][0]['@value']"></h2>
          <ExhibitionList v-if="currentLocation && hasDatePhotoInStore(photo)"
            v-for="(photo, i) in photos"
            :photoId="photo['o:id']"
            :key="'photolist_item_instore_' + i" />
        </div>
        <!-- side -->
        <div class="col-lg-4 exhibition_side_col">
          <h1 v-if="currentLocation"
            v-html="translate.metadata_label['Ort'][language] +
            ': ' + currentLocation['dcterms:title'][0]['@value']"></h1>

          <!-- v-bind:location="location" -->
          <osmap v-if="currentLocation"
            v-bind:coordinates="latitudeLongitude"
            v-bind:title="locationlabel"
            v-bind:location="currentLocation"></osmap>
          <hr>
          <dl>
            <dt v-html="translate.metadata_label['thematische Kategorie'][language]"></dt>
            <dd><SubjectcategoryNavigation /></dd>
            <dt v-html="translate.metadata_label['Personen'][language]"></dt>
            <dd><PersonsNavigation /></dd>
            <dt v-html="translate.metadata_label['Orte'][language]"></dt>
            <dd><LocationsNavigation /></dd>
          </dl>
          <hr>
          <GolbalListLinks />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import ExhibitionList from '@/components/partials/ExhibitionList.vue'
import SubjectcategoryNavigation from '@/components/partials/SubjectcategoryNavigation.vue'
import PersonsNavigation from '@/components/partials/PersonsNavigation.vue'
import LocationsNavigation from '@/components/partials/LocationsNavigation.vue'
import GolbalListLinks from '@/components/partials/GolbalListLinks.vue'
import osmap from '@/components/partials/Map.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Location',
  components: {
    'site-header': header,
    ExhibitionList,
    SubjectcategoryNavigation,
    PersonsNavigation,
    LocationsNavigation,
    GolbalListLinks,
    osmap
  },
  data () {
    return {
      currentLocation: null,
    }
  },
  watch: {
    '$route' (to) {
      this.setcurrentLocation(to.params.id)
    },
    locations: function () {
      if (this.locations.length > 0) {
        this.setcurrentLocation(this.$route.params.id)
      }
    }
  },
  methods: {
    setcurrentLocation(id) {
      if (this.locations && typeof this.locations !== 'undefined') {
        this.currentLocation = this.locations.find(location => location['o:id'] === Number(id))
      }
    },
    hasDatePhotoInExhibition(id) {
      let location = this.photos.find(photo =>
        photo['o:id'] === id &&
        typeof photo['ghwk:location'] !== 'undefined' &&
        this.currentLocation !== null &&
        typeof this.currentLocation !== 'undefined' &&
        typeof photo['ghwk:location'].find(
          locationProp => locationProp['value_resource_id'] === this.currentLocation['o:id']) !== 'undefined'
      )
      return (typeof location !== 'undefined')? true : false
    },
    hasDatePhotoInStore(photo) {
      if (photo['ghwk:exhibitstatus'][0]['@value'] !== 'im Bestand') {
        return false
      }
      if (photo && typeof photo['ghwk:location'] !== 'undefined') {
        let result = photo['ghwk:location'].find(locationProp =>
          this.currentLocation !== null &&
          typeof this.currentLocation !== 'undefined' &&
          locationProp['value_resource_id'] === this.currentLocation['o:id']
        )
        return (typeof result !== 'undefined')? true : false
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photosInExhibition: 'items/photosInExhibition',
      locations: 'locations/locations'
    }),
    latitudeLongitude: function () {
      return (typeof this.currentLocation !== 'undefined' &&
      this.currentLocation !== null &&
      typeof this.currentLocation['ghwk:latitude'] !== 'undefined' &&
      this.currentLocation['ghwk:latitude'].length > 0 &&
      typeof this.currentLocation['ghwk:longitude'] !== 'undefined' &&
      this.currentLocation['ghwk:longitude'].length > 0)?
        {
          latitude: this.currentLocation['ghwk:latitude'][0]['@value'],
          longitude: this.currentLocation['ghwk:longitude'][0]['@value']
        } : false
    },
    locationlabel: function () {
      let location = ''
      if (typeof this.currentLocation !== 'undefined' &&
        this.currentLocation !== null &&
        typeof this.currentLocation['ghwk:locationlabel'] !== 'undefined' &&
        this.currentLocation['ghwk:locationlabel'].length > 0)
      {
        location = this.currentLocation['ghwk:locationlabel'].find(prop => prop['@language'] === this.language)
        if (location !== '' && typeof location !== 'undefined') { location = location['@value'] }
      }
      return location.replace(/(<([^>]+)>)/ig,"")
    },
  },
  created () {
    if (this.$route.params.id) {
      this.setcurrentLocation(this.$route.params.id)
    }
  }
}
</script>